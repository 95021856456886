<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Planeaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Planeaciones</li>
                  <li class="breadcrumb-item active">Formulario {{ accion }}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="col-6 pt-2 pb-2">
                <h5 class="mb-0">Formulario {{ accion }} Planeaciones</h5>
              </div>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="tab-Principal"
                    data-toggle="tab"
                    href="#Principal"
                    >Principal</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Masivos"
                    data-toggle="tab"
                    href="#Masivos"
                    v-if="id != null"
                    >Masivos</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="tab-Correos"
                    data-toggle="tab"
                    href="#Correos"
                    v-if="id != null"
                    >Transportadoras</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="Principal">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="fecha_inicial">Fecha inicial</label>
                          <input
                            type="date"
                            :disabled="definitivo == 1"
                            class="form-control form-control-sm"
                            id="fecha_inicial"
                            v-model="form.fecha_inicial"
                            :class="
                              $v.form.fecha_inicial.$invalid ? 'is-invalid' : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="fecha_final">Fecha final</label>
                          <input
                            type="date"
                            :disabled="definitivo == 1"
                            class="form-control form-control-sm"
                            id="fecha_final"
                            v-model="form.fecha_final"
                            :class="
                              $v.form.fecha_final.$invalid ? 'is-invalid' : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="estado">Estado</label>
                          <select
                            class="form-control form-control-sm"
                            :disabled="definitivo == 1"
                            v-model="form.estado"
                            :class="$v.form.estado.$invalid ? 'is-invalid' : 'is-valid'"
                            @change="iniciarFecha"
                          >
                            <option value>Seleccione...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3" v-if="form.estado === 2">
                        <div class="form-group">
                          <label for="fecha_final_oferta">Fecha final oferta</label>
                          <input
                            :enabled="form.estado == 2"
                            :disabled="definitivo == 1"
                            type="date"
                            class="form-control form-control-sm"
                            id="fecha_final_oferta"
                            v-model="form.fecha_final_oferta"
                            :class="
                              $v.form.fecha_final_oferta.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="observaciones">Observaciones</label>
                          <br />
                          <textarea
                            vtype="date"
                            :disabled="definitivo == 1"
                            class="form-control"
                            id="fecha_final"
                            v-model="form.observaciones"
                            rows="2"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <PlaneacionVolumen v-if="id != null" ref="PlaneacionVolumen" />
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="Masivos"
                  v-if="$store.getters.can('hidrocarburos.planeaciones.documento')"
                >
                  <div class="card-body">
                    <PlaneacionDocumento></PlaneacionDocumento>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="Correos"
                  v-if="$store.getters.can('hidrocarburos.planeaciones.correo')"
                >
                  <div class="card-body">
                    <PlaneacionCorreo ref="PlaneacionCorreo"></PlaneacionCorreo>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i>
                    <br />Volver
                  </button>
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="
                      ($store.getters.can('hidrocarburos.planeaciones.create') ||
                        $store.getters.can('hidrocarburos.planeaciones.edit')) &&
                      definitivo == 0
                    "
                  >
                    <i class="fas fa-paper-plane"></i>
                    <br />Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PlaneacionDocumento from "./PlaneacionDocumento";
import PlaneacionCorreo from "./PlaneacionCorreo";
import { required } from "vuelidate/lib/validators";
import PlaneacionVolumen from "./PlaneacionVolumen";
import Loading from "../../../../components/Loading";

export default {
  name: "PlaneacionForm",
  components: {
    PlaneacionVolumen,
    PlaneacionDocumento,
    PlaneacionCorreo,
    Loading,
  },
  data() {
    return {
      empresas: null,
      definitivo: 0,
      form: {
        fecha_inicial: null,
        fecha_final: null,
        fecha_final_oferta: null,
        estado: null,
      },
      acto: null,
      accion: "",
      metodo: "",
      listasForms: {
        estados: [],
        empresas: [],
      },
      id: null,
      cargando: false,
    };
  },
  validations: {
    form: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
      estado: {
        required,
      },
      fecha_final_oferta: {
        required,
      },
    },
  },
  methods: {
    iniciarFecha() {
      if (this.form.estado === 1) {
        this.form.fecha_final_oferta = this.form.fecha_final;
      }
      if (this.form.estado === 2) {
        this.form.fecha_final_oferta = null;
      }
    },
    getEstados() {
      this.cargando = true;
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
        this.cargando = false;
      });
    },

    getEmpresas() {
      axios.get("/api/admin/empresas/lista").then((response) => {
        this.listasForms.empresas = response.data;
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/hidrocarburos/planeaciones",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;
            this.id = this.form.id;
            this.$swal({
              icon: "success",
              title: "La planeacion se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
            this.accion = "Editar";
            this.metodo = "PUT";
            if (this.form.estado == 2) {
              this.definitivo = 1;
            }
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    back() {
      return this.$router.replace("/Hidrocarburos/Planeaciones");
    },

    init() {
      this.accion = this.$route.params.accion;
      this.id = this.$route.params.id;
      if (this.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.metodo = "PUT";
        this.$refs.PlaneacionCorreo.planeacion_id = this.id;
        this.$refs.PlaneacionCorreo.init();
      } else {
        this.metodo = "POST";
      }

      if (this.form.estado == 2) {
        this.definitivo = 1;
      }
    },
  },
  mounted() {
    this.init();
    this.getEstados();
    this.getEmpresas();
  },
};
</script>
