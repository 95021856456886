var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"col-sm-12"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Resumen Planeación")]),_c('nav',{staticClass:"mt-2"},_vm._l((_vm.listaVolumenes),function(origen){return _c('ul',{key:origen.id,staticClass:"nav nav-pills nav-sidebar flex-column nav-flat nav-child-indent",attrs:{"data-widget":"treeview","role":"menu","data-accordion":"false"}},[_c('li',{staticClass:"nav-item has-treeview"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_c('i',{staticClass:"nav-icon fas fa-circle"}),_c('p',[_vm._v(" "+_vm._s(origen.nombre)+" | Volumen total: "+_vm._s(origen.volumen_total)+" "),_c('i',{staticClass:"right fas fa-angle-left"})])]),_vm._l((origen.productos_liquidos),function(producto){return _c('ul',{key:producto.id,staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_c('i',{staticClass:"far fa-circle nav-icon"}),_c('p',[_vm._v(_vm._s(producto.nombre))]),_c('i',{staticClass:"right fas fa-angle-left"})]),_vm._l((producto.destinos),function(destino){return _c('ul',{key:destino.id,staticClass:"nav nav-treeview"},[_c('li',{staticClass:"nav-item"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},[_c('a',{staticClass:"nav-link"},[_c('i',{staticClass:"far fa-dot-circle nav-icon"}),_c('p',[_vm._v(_vm._s(destino.nombre))])])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group row text-right"},[_c('label',{staticClass:"col-sm-4 col-form-label"},[_vm._v("API")]),_c('div',{staticClass:"col-sm-8"},[_c('input',{staticClass:"form-control form-control-sm",attrs:{"type":"number","id":'api_' + producto.id + '_' + origen.id + '_' + destino.id},domProps:{"value":destino.planeaciones_volumen !== null
                                ? destino.planeaciones_volumen.api
                                : producto.api}})])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group row text-right"},[_c('label',{staticClass:"col-sm-4 col-form-label"},[_vm._v("% Azufre")]),_c('div',{staticClass:"col-sm-8"},[_c('input',{staticClass:"form-control form-control-sm",attrs:{"type":"number","id":'azufre_' +
                              producto.id +
                              '_' +
                              origen.id +
                              '_' +
                              destino.id},domProps:{"value":destino.planeaciones_volumen !== null
                                ? destino.planeaciones_volumen.porcentaje_azufre
                                : producto.azufre}})])])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group row text-right"},[_c('label',{staticClass:"col-sm-4 col-form-label"},[_vm._v("Volumen")]),_c('div',{staticClass:"col-sm-8"},[_c('input',{staticClass:"form-control form-control-sm",attrs:{"type":"number","id":'volumen_' +
                              producto.id +
                              '_' +
                              origen.id +
                              '_' +
                              destino.id},domProps:{"value":destino.planeaciones_volumen !== null
                                ? destino.planeaciones_volumen.volumen
                                : ''}})])])]),_c('div',{staticClass:"col-md-1"},[_c('div',{staticClass:"btn-group"},[_c('input',{attrs:{"type":"hidden","id":'id_' + producto.id + '_' + origen.id + '_' + destino.id},domProps:{"value":destino.planeaciones_volumen !== null
                              ? destino.planeaciones_volumen.id
                              : ''}}),(
                            _vm.$store.getters.can(
                              'hidrocarburos.planeaciones.agregarVolumen'
                            )
                          )?_c('button',{staticClass:"btn btn-sm",class:destino.planeaciones_volumen !== null
                              ? 'btn-success'
                              : 'btn-warning',attrs:{"type":"button"},on:{"click":function($event){return _vm.save(_vm.planeacion_id, origen.id, destino.id, producto.id)}}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e(),(
                            destino.planeaciones_volumen !== null &&
                            _vm.$store.getters.can('hidrocarburos.planeaciones.delete')
                          )?_c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.destroy(destino.planeaciones_volumen.id)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()])])])])])})],2)])})],2)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }