<template>
  <div>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-md-3">
          <label class="col-md-12">Plantilla</label>
          <button class="btn bg-primary" @click="downloadWithAxios">
            <i class="fas fa-file-download"></i> Descargar
          </button>
        </div>
        <div class="form-group col-md-4">
          <label class="col-md-12">Importar</label>
          <input
            :disabled="$parent.definitivo == 1"
            type="file"
            id="file"
            ref="file"
            v-on:change="elegirDirectorio()"
            accept=".XLSX, .CSV"
            class="form-control btn btn-secundary"
          />
        </div>
        <div class="form-group col-md-4">
          <label class="col-md-12"></label>
          <button
            v-if="$parent.definitivo != 1"
            v-on:click="importarPlaneacion()"
            class="btn btn-primary"
          >
            <i class="fas fa-file-upload"></i> Subir
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "PlaneacionDocumento",
  components: {},
  data() {
    return {
      file: "",
    };
  },
  methods: {
    importarPlaneacion() {
      this.$parent.cargando = true;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("planeacion_id", this.$parent.form.id);
      axios
        .post("/api/hidrocarburos/planeacionesVolumenes/import", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$parent.$refs.PlaneacionVolumen.getIndex();
          this.$swal({
            icon: "success",
            title: "La planeacion de Volumenes se guardo exitosamente",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
    elegirDirectorio() {
      this.file = this.$refs.file.files[0];
    },

    downloadWithAxios() {
      this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/hidrocarburos/planeacionesVolumenes/export",
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
  },
};
</script>
