<template>
  <div>
    <div class="col-sm-12">
      <h5 class="mb-0">Resumen Planeación</h5>
      <nav class="mt-2">
        <ul
          data-widget="treeview"
          role="menu"
          data-accordion="false"
          class="nav nav-pills nav-sidebar flex-column nav-flat nav-child-indent"
          v-for="origen in listaVolumenes"
          :key="origen.id"
        >
          <li class="nav-item has-treeview">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-circle"></i>
              <p>
                {{ origen.nombre }} | Volumen total: {{ origen.volumen_total }}
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul
              class="nav nav-treeview"
              v-for="producto in origen.productos_liquidos"
              :key="producto.id"
            >
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ producto.nombre }}</p>
                  <i class="right fas fa-angle-left"></i>
                </a>
                <ul
                  class="nav nav-treeview"
                  v-for="destino in producto.destinos"
                  :key="destino.id"
                >
                  <li class="nav-item">
                    <div class="row">
                      <div class="col-md-2">
                        <a class="nav-link">
                          <i class="far fa-dot-circle nav-icon"></i>
                          <p>{{ destino.nombre }}</p>
                        </a>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group row text-right">
                          <label class="col-sm-4 col-form-label">API</label>
                          <div class="col-sm-8">
                            <input
                              type="number"
                              :id="
                                'api_' + producto.id + '_' + origen.id + '_' + destino.id
                              "
                              class="form-control form-control-sm"
                              :value="
                                destino.planeaciones_volumen !== null
                                  ? destino.planeaciones_volumen.api
                                  : producto.api
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group row text-right">
                          <label class="col-sm-4 col-form-label">% Azufre</label>
                          <div class="col-sm-8">
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              :id="
                                'azufre_' +
                                producto.id +
                                '_' +
                                origen.id +
                                '_' +
                                destino.id
                              "
                              :value="
                                destino.planeaciones_volumen !== null
                                  ? destino.planeaciones_volumen.porcentaje_azufre
                                  : producto.azufre
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group row text-right">
                          <label class="col-sm-4 col-form-label">Volumen</label>
                          <div class="col-sm-8">
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              :id="
                                'volumen_' +
                                producto.id +
                                '_' +
                                origen.id +
                                '_' +
                                destino.id
                              "
                              :value="
                                destino.planeaciones_volumen !== null
                                  ? destino.planeaciones_volumen.volumen
                                  : ''
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <div class="btn-group">
                          <input
                            type="hidden"
                            :id="'id_' + producto.id + '_' + origen.id + '_' + destino.id"
                            :value="
                              destino.planeaciones_volumen !== null
                                ? destino.planeaciones_volumen.id
                                : ''
                            "
                          />
                          <button
                            type="button"
                            class="btn btn-sm"
                            :class="
                              destino.planeaciones_volumen !== null
                                ? 'btn-success'
                                : 'btn-warning'
                            "
                            @click="
                              save(planeacion_id, origen.id, destino.id, producto.id)
                            "
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.planeaciones.agregarVolumen'
                              )
                            "
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              destino.planeaciones_volumen !== null &&
                              $store.getters.can('hidrocarburos.planeaciones.delete')
                            "
                            @click="destroy(destino.planeaciones_volumen.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PlaneacionVolumen",
  data() {
    return {
      form: {},
      planeacion_id: null,
      listaVolumenes: [],
    };
  },
  methods: {
    getIndex() {
      this.planeacion_id = this.$route.params.id;
      this.getVolumenes();
    },

    getVolumenes() {
      this.$parent.cargando = true;
      axios
        .get(`/api/hidrocarburos/planeacionesVolumenes/datosPlantilla`, {
          params: { planeacion_id: this.planeacion_id },
        })
        .then((response) => {
          this.listaVolumenes = response.data;
          this.$parent.cargando = false;
        });
    },

    save(planeacion_id, origen_id, destino_id, producto_liquido_id) {
      const id = document.querySelector(
        "#id_" + producto_liquido_id + "_" + origen_id + "_" + destino_id
      ).value;
      const api = document.querySelector(
        "#api_" + producto_liquido_id + "_" + origen_id + "_" + destino_id
      ).value;
      const porcentaje_azufre = document.querySelector(
        "#azufre_" + producto_liquido_id + "_" + origen_id + "_" + destino_id
      ).value;
      const volumen = document.querySelector(
        "#volumen_" + producto_liquido_id + "_" + origen_id + "_" + destino_id
      ).value;

      if (api && porcentaje_azufre && volumen) {
        const params = {
          id,
          planeacion_id,
          origen_id,
          destino_id,
          producto_liquido_id,
          api,
          porcentaje_azufre,
          volumen,
        };
        this.$parent.cargando = true;
        axios({
          method: "POST",
          url: "/api/hidrocarburos/planeacionesVolumenes",
          data: params,
        })
          .then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Volumen asignado a la planeación exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          });
      } else {
        this.$swal({
          icon: "error",
          title:
            "Debe digitar todos los datos para guardar el volumen de la planeación...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    destroy(idPlaneacionVolumen) {
      this.$swal({
        title: "Está seguro de eliminar este Volumen de Planeación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/hidrocarburos/planeacionesVolumenes/" + idPlaneacionVolumen)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se eliminó el Volumen de Planeación exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
  mounted() {
    this.getIndex();
  },
};
</script>
